export const onlineVisitorsData = [
    {
        name: 'Page A',
        "online users": 600,
    },
    {
        name: 'Page B',
        "online users": 2000,
    },
    {
        name: 'Page B',
        "online users": 1800,
    },
    {
        name: 'Page D',
        "online users": 3000,
    },
    {
        name: 'Page E',
        "online users": 2800,
    },
    {
        name: 'Page F',
        "online users": 3200,
    },
    {
        name: 'Page G',
        "online users": 2200,
    },
    {
        name: 'Page F',
        "online users": 3100,
    },
    {
        name: 'Page G',
        "online users": 2490,
    },
    {
        name: 'Page G',
        "online users": 3400,
    },
];
